.mainHeader {
    top: 0;
    width: 100%;
    height: 5%;
    position: fixed;
    opacity: 0.6;
    background: transparent;
    background-color: black;
    z-index: 2;
}

.signPageLink {
    background: transparent;
    margin-left: 10px;
    border: none;
    border-bottom: 1px solid white;
    color: white;
    font-weight: 600;
    cursor: pointer;
    bottom: 0;
}

.ant-btn-link .ant-tabs-tab-btn {
    color: #fff !important;
}


.mainBody {
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    position: fixed;
    background-size: cover;
    z-index: 1;
}

.homecontent {
    background-image: url('../public/schoolimage.jpeg');
}

.eventcontent {
    background-image: url('../public/eventpage.JPG');
}

.donationcontent {
    padding-left: 1rem;
    background-image: url('../public/donation.jpg');
    /* background-color: grey; */
}

.mainFooter {
    bottom: 0;
    width: 100%;
    height: 5%;
    position: fixed;
    opacity: 0.6;
    background: transparent;
    background-color: black;
    z-index: 2;
}

.mainFooter>p {
    position: fixed;
    width: 100%;
    color: #fff;
    line-height: 4px;
    font-size: 0.9em;
    text-align: center;
    bottom: 0;
}

.logo {
    background-image: url("../public/SevaLogo.png");
    height: 10rem;
    width: 20rem;
    margin: 4% 8%;
    border-radius: 9%;
    position: absolute;
}

.scannerCode {
    src: url('../public/scancode.png');
}

.donationimage {

    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
}

.blackoverlay {
    background: rgba(0, 0, 0, 0.5);
    width: 98.5%;
    height: 85%;
    /* margin-left: 0rem; */
    margin-top: 3rem;
    padding: 1.5rem;
}

.centerItem {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

}